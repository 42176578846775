<template>
  <b-row v-if="breadcrumbs || $route.meta.pageTitle" class="content-header p-0">
    <b-col class="content-header-left p-0" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2
            class="content-header-title float-left mb-0 p-0 text-secondary font-weight-bolder breadcrumb-text"
          >
            {{ $route.meta.pageTitle }}
          </h2>

          <div class="breadcrumb-wrapper w-100">
            <b-breadcrumb class="p-0">
              <b-breadcrumb-item
                v-for="(item, index) in breadcrumbs"
                :key="index"
                @click="handleNavigation(item, index + 1)"
              >
                <h2 class="font-weight-bolder text-secondary breadcrumb-text">
                  <feather-icon
                    v-if="index !== 0"
                    icon="ChevronRightIcon"
                    size="21"
                    color="secondary"
                  />
                  {{ item.text }}
                </h2>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { APP_ROUTES, getRoute } from "../helpers/routeHelpers";

export default {
  name: "Breadcrumb",
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  data() {
    return {
      breadcrumbs: this.$route.meta.breadcrumb,
    };
  },
  methods: {
    handleNavigation(item, index) {
      const { paramKey } = item;

      if (index == this.breadcrumbs.length) {
        return;
      }

      if (paramKey) {
        let params = JSON.parse(sessionStorage.getItem("params"));
        this.$router.push(getRoute(item.to, { ...params }));
      } else {
        this.$router.push(item.to);
      }
    },
  },

  watch: {
    $route(to, from) {
      if (!this.$route.meta.breadcrumb) {
        this.breadcrumbs = [];
        return;
      }
      this.breadcrumbs = this.$route.meta.breadcrumb.map((breadCrum) => {
        const updatedCrum = { ...breadCrum };
        if (updatedCrum.routeName === to.name) {
          updatedCrum.to = to.path;
        } else if (updatedCrum.routeName === from.name) {
          updatedCrum.to = from.path;
        }
        return updatedCrum;
      });
    },
  },
};
</script>
<style scoped>
.breadcrumb-text {
  font-size: 22px;
}
.breadcrumb-item::before {
  display: none;
}
</style>
