<template>
  <div class="navbar-container d-flex content align-items-center pt-0 pb-0">
    <!-- Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div class="w-100 m-1 ml-2">
      <Breadcrumb />
    </div>
    <!-- Admin Section -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ adminName }}</p>
            <span class="user-status">{{ role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          v-for="(item, index) in adminMenuItems"
          :key="index"
        >
          <feather-icon size="16" :icon="item.iconName" class="mr-50" />
          <span>{{ item.menuItem }}</span>
        </b-dropdown-item> 

        <b-dropdown-divider />-->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="handleLogout"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { mapMutations } from "vuex";

import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import { SIGN_OUT } from "../../store/auth";

export default {
  name: "Header",
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Breadcrumb,
    // Navbar Components
    DarkToggler,
  },
  props: {
    adminName: String,
    role: String,
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    ...mapMutations([SIGN_OUT]),
    handleLogout() {
      this.signOut();
    },
  },
  data() {
    return {
      adminMenuItems: [
        { menuItem: "Profile", iconName: "UserIcon" },
        { menuItem: "Inbox", iconName: "MailIcon" },
        { menuItem: "Task", iconName: "CheckSquareIcon" },
        { menuItem: "Chat", iconName: "MessageSquareIcon" },
      ],
    };
  },
};
</script>
